<template>
  <div :class="boxClasses" :style="boxStyles">
    <div :class="overlayClasses">
      <component :is="'as'" :src="mediaSrc" allowfullscreen v-if="isEmbededVideo" />
      <div :class="textContentClasses" v-if="hasTextContent">
        <div>
          <slot class="text-top" name="top" />
        </div>
        <div>
          <slot class="text-bottom" name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { variationValidators } from '@/utils/validators';
// import variations from './variations';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';

export default {
  name: 'VueMediaContentBox',
  props: {
    as: {
      type: String,
      default: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.image,
      // validator: value => variationValidators(variations, value), // TODO: @Kuli, bu prop validasyonuna bakılacak!
    },
    aspectRatios: {
      type: String,
      default: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS.by_1_1,
    },
    backgroundColor: {
      type: String,
    },
    mediaSrc: {
      type: String,
      required: true,
    },
    hasOverlay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    boxClasses() {
      const mediaContentBoxClasses = [this.$style['media-content-box']];

      // set aspect ratio
      mediaContentBoxClasses.push(this.$style[`media-content-box-${this.aspectRatios}`]);

      return mediaContentBoxClasses;
    },
    isEmbededVideo() {
      return this.as === MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.embed;
    },
    boxStyles() {
      const boxStyleObj = {};

      if (this.backgroundColor) {
        boxStyleObj.backgroundColor = this.backgroundColor;
      }

      if (this.as === MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES.image) {
        boxStyleObj.backgroundImage = this.mediaSrc;
      }

      return boxStyleObj;
    },
    hasTextContent() {
      return !this.isEmbededVideo && (this.$slots.top || this.$slots.bottom);
    },
    textContentClasses() {
      const classes = [];
      if (!this.isEmbededVideo && (this.$slots.top || this.$slots.bottom)) {
        classes.push(this.$style['text-content']);
      }

      return classes;
    },
    overlayClasses() {
      const classes = [this.$style['overlay-holder']];

      if (this.hasOverlay) {
        classes.push(this.$style['overlay-holder-has']);
      }
      return classes;
    },
  },
};
</script>

<style module lang="scss">
.media-content-box {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.media-content-box-1by1 {
  &:before {
    padding-top: 100%;
  }
}
.media-content-box-4by3 {
  &:before {
    padding-top: 75%;
  }
}
.media-content-box-14by9 {
  &:before {
    padding-top: calc(250 / 375 * 100%);
  }
}
.media-content-box-16by9 {
  &:before {
    padding-top: 56.25%;
  }
}
.media-content-box-21by9 {
  &:before {
    padding-top: 42.857143%;
  }
}
.text-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  justify-content: space-between;
  z-index: 2;

  > div:first-child {
    align-self: flex-start;
    width: 100%;
  }
  > div:last-child {
    align-self: flex-end;
    width: 100%;
  }
}

.overlay-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.overlay-holder-has {
  background-color: transparentize(palette-color-level('grey', '50'), 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
</style>
