<template>
  <VueContentHolder id="divLoginMainWrapper">
    <VueMediaContentBox :mediaSrc="bgUrl" aspectRatios="14by9">
      <div class="mediabox-content mediabox-top-content" slot="top">
        <img src="~@/assets/logos/pmaktif-alt.png" alt="welcome-logo" />
      </div>
    </VueMediaContentBox>
    <div class="landing-footer">
      <img src="@/assets/logos/philip-morris-logo.png" class="logo" alt="philip-morris-logo" />
      <div class="footer-right-area">
        <VueText sizeLevel="3" weightLevel="1" color="grey-30" class="footer-text"
          >PMAktif portal, tacir/esnaf niteliği taşıyan satış noktalarına yönelik olarak
          hazırlanmıştır. Siteyi kullanmak istiyorsanız, satış temsilciniz ile iletişime
          geçiniz.</VueText
        >
        <div class="footer-link-holder">
          <VueText sizeLevel="3" weightLevel="3" color="grey-30" class="footer-left-link"
            >Yasal Şartlar</VueText
          >
          <VueText sizeLevel="3" weightLevel="3" color="grey-30" class="footer-right-link"
            >Gizlilik Bildirimi</VueText
          >
        </div>
      </div>
    </div>
  </VueContentHolder>
</template>
<script>
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import { Account } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueMediaContentBox from '@/components/shared/VueMediaContentBox/VueMediaContentBox.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import { clearAppData } from '@/mixins/storageUtils.js';
export default {
  name: 'PmtepLogin',
  components: {
    VueMediaContentBox,
    VueText,
    VueContentHolder,
  },
  data() {
    return {
      bgUrl: `url(${require('@/assets/images/login.png')})`,
    };
  },
  created() {
    clearAppData('login');
    const userToken = this.$route.params.token;
    if (userToken) {
      this.$router.push({ name: RoutesAthentication.PmtepLogin.name });
      Account.loginPmtep(userToken).then(res => {
        const { Data } = res ? res.data : { Data: null };
        this.$store.dispatch('auth/setAuthData', Data).then(() => {
          if (Data) {
            // // Fetch the storage field which number user sent
            // new StorageHelper({
            //   id: process.env[storageFieldName],
            // }).set(this.rememberMe ? number : null);
            const { token, refreshToken, user, pos } = Data;
            if (token) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(token);
            }
            if (refreshToken) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                refreshToken.token,
              );
            }
            if (user) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).set(user);
            }
            if (pos) {
              const posArr = Array.isArray(pos) ? pos : [pos];
              if (posArr.length > 1) {
                this.$store.dispatch('auth/setPosList', posArr).then(() => {
                  this.$router.push(
                    `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectPos.path}`,
                  );
                });
              } else {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).set(
                  posArr[0],
                );
                window.location = `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`;
              }
            }
          }
        });
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/styles';
.mediabox-content {
  padding: 0 palette-space-level('20');
  color: $brand-text-secondary-color;

  &.mediabox-top-content {
    text-align: left;
    padding-top: palette-space-level('35');
    img {
      transform: scale(1.2);
      transform-origin: left;
    }
  }
  &.mediabox-bottom-content {
    h1 {
      padding-bottom: palette-space-level('12');
    }
    p {
      padding-bottom: palette-space-level('20');
    }
  }
}

.modal {
  line-height: 1.86;
}
.landing-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding-bottom: palette-space-level('30');
  width: 100%;

  .logo {
    width: 110px;
    padding-left: palette-space-level('20');
  }

  .footer-right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;

    .footer-text {
      line-height: 1.27;
      padding-right: palette-space-level('10');
    }

    .footer-link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .footer-left-link {
        padding-right: palette-space-level('30');
      }
      .footer-left-link,
      .footer-right-link {
        padding-top: palette-space-level('10');
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}
</style>
